.analytics-container {
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .table-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
  
  .filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 16px;
  }
  
  .filters-left {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
  }
  
  .filters-right {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .date-picker-container {
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Dropdown styles */
  .nextui-dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
    margin-top: 4px;
  }
  
  .nextui-dropdown-menu::-webkit-scrollbar {
    width: 6px;
  }
  
  .nextui-dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .nextui-dropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .nextui-dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .table-loading {
    display: flex;
    justify-content: center;
    padding: 2rem;
    width: 100%;
  }
  
  .table-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    width: 100%;
    gap: 1rem;
  }
  
  .table-section:first-child {
    margin-bottom: 24px;
  }
  
  .table-section table {
    margin-top: 16px;
  }
  
  .table-section table th {
    font-size: 16px;
    font-weight: 600;
    color: #202020;
  }
  
  .global-refresh-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(to right, #0072F5, #7828C8);
    z-index: 1000;
    animation: loadingBar 2s infinite;
  }
  
  @keyframes loadingBar {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .global-filters {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
  
  .date-filter-container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
  }
  
  .date-picker-container {
    padding: 1rem;
    background: white;
    border-radius: 8px;
    min-width: 300px;
  }